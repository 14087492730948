"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const firestar_1 = require("firestar");
var Users;
(function (Users) {
    function create(userId, document) {
        return firestar_1.Actions.writeDocument(`users/${userId}`, document);
    }
    Users.create = create;
    function getAll(descriptor) {
        return firestar_1.Actions.readCollection('users', descriptor);
    }
    Users.getAll = getAll;
    function get(userId) {
        return firestar_1.Actions.readDocument(`users/${userId}`);
    }
    Users.get = get;
    Users.loggedInUser = firestar_1.Users.loggedInUser;
})(Users || (Users = {}));
exports.default = Users;
