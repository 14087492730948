"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const firestar_1 = require("firestar");
var Providers;
(function (Providers) {
    function getProvider(host, appConfig) {
        var _a, _b;
        const hosts = (_a = appConfig.default.hosts) !== null && _a !== void 0 ? _a : [];
        if (hosts.includes(host)) {
            return appConfig.default;
        }
        else {
            const providers = (_b = appConfig.providers) !== null && _b !== void 0 ? _b : [];
            const provider = providers.find((p) => p.hosts.includes(host));
            return provider !== null && provider !== void 0 ? provider : null;
        }
    }
    Providers.getProvider = getProvider;
    function get(id) {
        return firestar_1.Actions.readDocument(`providers/${id}`);
    }
    Providers.get = get;
    function getAll(descriptor) {
        return firestar_1.Actions.readCollection('providers', descriptor);
    }
    Providers.getAll = getAll;
})(Providers || (Providers = {}));
exports.default = Providers;
