import React, { useEffect, useState } from 'react'
import {
	BrowserRouter,
	Switch,
	Route
} from 'react-router-dom'
import firebase from 'firebase/compat/app'
import Login from './Login'
import Home from './Home'

export default function App() {

	const [userId, setUserId] = useState<string>()
	const [showLoader, setShowLoader] = useState(true)

	useEffect(() => {
		firebase.auth().onAuthStateChanged(onAuthStateChanged)
	}, [])

	if (showLoader == true) return <div>Loading...</div>

	return (
		<div style={{
			width: '100%'
		}}>
			<BrowserRouter>
				<Switch>
					{userId != null ? (
						<Route path='/' component={Home} />
					) : (
						<Route exact path='/' component={Login} />
					)}
				</Switch>
			</BrowserRouter>
		</div>
	)

	async function onAuthStateChanged(user: firebase.User | null) {
		setUserId(user?.uid)
		if (user?.uid) {
			console.log('Logged In With ', user.uid)
			const token = await user?.getIdTokenResult(true)
			const role = token.claims.roles['global']
			console.log('role',role)
			if(role == null){
				firebase.auth().signOut()
			}
		}
		setShowLoader(false)
	}
}
