"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const firestar_1 = require("firestar");
var Admin;
(function (Admin) {
    function addUser(userId, document) {
        return firestar_1.Actions.writeDocument(`settings/manage-users/users/${userId}`, document);
    }
    Admin.addUser = addUser;
    function deleteUser(userId) {
        return firestar_1.Actions.deleteDocument(`settings/manage-users/users/${userId}`);
    }
    Admin.deleteUser = deleteUser;
    function getUser(userId) {
        return firestar_1.Actions.readDocument(`settings/manage-users/users/${userId}`);
    }
    Admin.getUser = getUser;
    function getAllUser(descriptor) {
        return firestar_1.Actions.readCollection('settings/manage-users/users', descriptor);
    }
    Admin.getAllUser = getAllUser;
    function getUserClaims(userId) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `https://us-central1-lapps-in.cloudfunctions.net/getUserClaims?userId=${userId}`;
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                if (response.ok) {
                    const contentType = response.headers.get('content-type');
                    const result = contentType.indexOf('application/json');
                    if (result >= 0) {
                        resolve(await response.json());
                    }
                    else {
                        resolve(undefined);
                    }
                }
                else {
                    throw new Error('Request Failed');
                }
            }
            catch (error) {
                reject(error);
            }
        });
    }
    Admin.getUserClaims = getUserClaims;
})(Admin || (Admin = {}));
exports.default = Admin;
