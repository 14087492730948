"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProjectStatusOptions = exports.LicenseStatusOptions = void 0;
const firestar_1 = require("firestar");
exports.LicenseStatusOptions = {
    'none': 'None',
    'waiting': 'Waiting',
    'valid': 'Valid',
    'expired': 'Expired',
};
exports.ProjectStatusOptions = {
    'draft': 'Draft',
    'live': 'Live'
};
var Projects;
(function (Projects) {
    function get(projectId) {
        return firestar_1.Actions.readDocument(`projects/${projectId}`);
    }
    Projects.get = get;
    function getAll(descriptor) {
        return firestar_1.Actions.readCollection('projects', descriptor);
    }
    Projects.getAll = getAll;
    function create(projectId, document) {
        return firestar_1.Actions.writeDocument(`projects/${projectId}`, document);
    }
    Projects.create = create;
    function addUser(projectId, userId, document) {
        return firestar_1.Actions.writeDocument(`projects/${projectId}/users/${userId}`, document);
    }
    Projects.addUser = addUser;
    function getUsers(projectId) {
        return firestar_1.Actions.readCollection(`projects/${projectId}/users`);
    }
    Projects.getUsers = getUsers;
    function update(projectId, document) {
        return firestar_1.Actions.updateDocument(`projects/${projectId}`, document);
    }
    Projects.update = update;
    function uploadServiceAccount(projectId, serviceAccount) {
        const url = `projects/${projectId}/service-account.json`;
        const encoded = btoa(JSON.stringify(serviceAccount));
        return firestar_1.Storage.uploadFileDataToPath(url, encoded, 'base64', {
            contentType: 'application/json'
        });
    }
    Projects.uploadServiceAccount = uploadServiceAccount;
    function uploadAppConfig(projectId, config) {
        const url = `projects/${projectId}/app.config.json`;
        const encoded = btoa(JSON.stringify(config));
        return firestar_1.Storage.uploadFileDataToPath(url, encoded, 'base64', {
            contentType: 'application/json'
        });
    }
    Projects.uploadAppConfig = uploadAppConfig;
    function uploadFirebaseRc(projectId, config) {
        const url = `projects/${projectId}/.firebaserc`;
        const encoded = btoa(JSON.stringify(config));
        return firestar_1.Storage.uploadFileDataToPath(url, encoded, 'base64', {
            contentType: 'application/json'
        });
    }
    Projects.uploadFirebaseRc = uploadFirebaseRc;
    function getLicense(projectId) {
        return new Promise(async (resolve, reject) => {
            const payload = {
                projectId: projectId
            };
            try {
                const response = await fetch('https://us-central1-lapps-in.cloudfunctions.net/getLicense', {
                    method: 'POST',
                    body: JSON.stringify(payload),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                if (response.ok) {
                    const contentType = response.headers.get('content-type');
                    const result = contentType.indexOf('application/json');
                    if (result >= 0) {
                        resolve(await response.json());
                    }
                    else {
                        resolve(undefined);
                    }
                }
                else {
                    throw new Error('Request Failed');
                }
            }
            catch (error) {
                reject(error);
            }
        });
    }
    Projects.getLicense = getLicense;
    function createLicense(userId, projectId) {
        return new Promise(async (resolve, reject) => {
            const payload = {
                projectId: projectId,
                userId: userId
            };
            try {
                const response = await fetch('https://us-central1-lapps-in.cloudfunctions.net/createLicense', {
                    method: 'POST',
                    body: JSON.stringify(payload),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                if (response.ok) {
                    const contentType = response.headers.get('content-type');
                    const result = contentType.indexOf('application/json');
                    if (result >= 0) {
                        resolve(await response.json());
                    }
                    else {
                        resolve(undefined);
                    }
                }
                else {
                    throw new Error('Request Failed');
                }
            }
            catch (error) {
                reject(error);
            }
        });
    }
    Projects.createLicense = createLicense;
    function activateLicense(projectId) {
        return new Promise(async (resolve, reject) => {
            const payload = {
                projectId: projectId
            };
            try {
                const response = await fetch('https://us-central1-lapps-in.cloudfunctions.net/activateLicense', {
                    method: 'POST',
                    body: JSON.stringify(payload),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                if (response.ok) {
                    const contentType = response.headers.get('content-type');
                    const result = contentType.indexOf('application/json');
                    if (result >= 0) {
                        resolve(await response.json());
                    }
                    else {
                        resolve(undefined);
                    }
                }
                else {
                    throw new Error('Request Failed');
                }
            }
            catch (error) {
                reject(error);
            }
        });
    }
    Projects.activateLicense = activateLicense;
})(Projects || (Projects = {}));
exports.default = Projects;
