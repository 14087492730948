import React, { ChangeEvent, useEffect, useMemo, useState } from 'react'
import MaterialTable, { Column } from 'material-table'
import { Admin, Projects } from 'la-connect'
import { useSelector } from 'react-redux'
import { StoreState } from '../../console/src/store'
import lodash from 'lodash'
import ReactJson from 'react-json-view'
import {
	StylesProvider,
	createGenerateClassName
} from '@material-ui/styles';
const generateClassName = createGenerateClassName({
	productionPrefix: 'mt',
	seed: 'mt'
})

export default function ProjectsList() {

	const [projects, setProjects] = useState<Projects.Project[]>([])
	const [showLoader, setShowLoader] = useState(false)
	const columns = useMemo<Column<Projects.Project>[]>(() => {
		return [
			{
				field: 'name',
				title: 'Name'
			},
			{
				field: 'id',
				title: 'Project Id'
			}
		]
	}, [])

	useEffect(() => {
		fetchProjects()
	}, [])

	return (
		<StylesProvider generateClassName={generateClassName}>
			<MaterialTable
				title={'Projects'}
				options={{
					search: false,
				}}
				components={{
					Pagination: () => null
				}}
				detailPanel={(data) => (
					<ProjectDetail
						project={data} />
				)}
				isLoading={showLoader}
				columns={columns}
				data={projects} />
		</StylesProvider>
	)

	async function fetchProjects() {
		setShowLoader(true)
		try {
			const response = await Projects.getAll()
			setProjects(response.data)
		} catch (error) {
			console.log('Error Fetching Projects', error)
			alert('Error Fetching Projects' + String(error))
		}
		setShowLoader(false)
	}
}

function ProjectDetail(props: {
	project: Projects.Project
}) {

	const [project, setProject] = useState<Projects.Project>(lodash.cloneDeep(props.project))
	const [editing, setEditing] = useState(false)
	const [showLoader, setShowLoader] = useState(false)
	const [users, setUsers] = useState<Projects.ProjectUser[]>([])

	useEffect(() => {
		fetchUsers()
	}, [])

	if (showLoader == true) {
		return <progress />
	}

	return (
		<div style={{
			padding: 25
		}}>
			<MaterialTable
				title={'Users'}
				style={{
					width: 500
				}}
				options={{
					search: false,
				}}
				components={{
					Pagination: () => null,
					Header: () => null
				}}
				isLoading={showLoader}
				columns={[
					{
						field: 'id'
					},
					{
						field: 'displayName',
						emptyValue: 'No Name'
					},
					{
						render: () => (
							<button>Get Claims</button>
						)
					}
				]}
				data={users} />
			{/* <h1>Projects</h1>
			{editing == true ? (
				<button onClick={onClickSave}>Save</button>
			) : (
				<button onClick={onClickEdit}>Edit</button>
			)}
			{editing == false ? (
				<ReactJson
					collapsed={false}
					sortKeys
					name='config'
					style={{
						fontSize: 18,
						flexWrap: 'wrap',
						wordWrap: "break-word",
						textAlign: 'left'
					}}
					src={project?.config ?? {}} />
			) : (
				<input
					about='application/JSON'
					onChange={onChangeFileInput}
					multiple={false}
					type={'file'} />
			)} */}
			
		</div>
	)

	async function fetchUsers(){
		setShowLoader(true)
		try {
			const response = await Projects.getUsers(project.id)
			setUsers(response.data)
		} catch (error) {
			console.log('Error Fetching Users:', error)
		}
		setShowLoader(false)
	}

	async function onPressGetClaims(userId: string){
		try {
			const response = await Admin.getUserClaims(userId)
			console.log('Response', response)
		} catch (error) {
			alert('Error Getting User Claims' + String(error))
			console.log('Error Getting User Claims', error)
		}
	}

	function onClickEdit() {
		setEditing(true)
	}

	async function onClickSave() {

		console.log('project', project)

		delete (project as any).tableData;

		setShowLoader(true)
		try {
			await Projects.update(project.id, project)
			setEditing(false)
		} catch (error) {
			console.log('Error Saving Project', error)
			alert('Error Saving Project' + String(error))
		}
		setShowLoader(false)
	}

	function onChangeFileInput(event: ChangeEvent<HTMLInputElement>) {
		const files = event.target.files ?? []
		const file = files[0]
		const reader = new FileReader();
		reader.onload = function (event) {
			// The file's text will be printed here
			const config = event.target?.result ?? ''
			setProject({
				...project,
				config: JSON.parse(config as string)
			})
		};
		reader.readAsText(file);
	}
}