import React, { useMemo, useState } from 'react'
import Dashboard from './Dashboard'
import {
	Switch,
	Route,
	useHistory
} from 'react-router-dom'
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import firebase from 'firebase/compat/app'
import Projects from './Projects'

function Home() {

	const history = useHistory()
	const [showMenu, setShowMenu] = useState(false)
	const menuItems = useMemo(() => {
		const items = getMenuItems()
		return items
	}, [])

	return (
		<Box sx={{
			width: '100%'
		}}>
			<AppBar position="static">
				<Toolbar sx={{
					display: 'flex',
					justifyContent: 'space-between'
				}}>
					<Box display={'flex'} flexDirection={'row'} alignItems='center'>
						<IconButton
							size="small"
							edge="start"
							color="inherit"
							sx={{ mr: 2 }}>
							<MenuIcon onClick={() => setShowMenu(!showMenu)} />
						</IconButton>
						<Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
							Motherboard
						</Typography>
					</Box>
					<Button onClick={onClickLogout} color="inherit">Logout</Button>
				</Toolbar>
				<Drawer
					anchor={'left'}
					open={showMenu}
					onClose={() => setShowMenu(!showMenu)}>
					<List sx={{
						width: '200px'
					}}>
						{menuItems.map(({ title, id, path }) => (
							<ListItem key={id} disablePadding>
								<ListItemButton onClick={() => {
									history.push(path)
									setShowMenu(false)
								}}>
									<ListItemIcon>
										<DashboardIcon />
									</ListItemIcon>
									<ListItemText primary={title} />
								</ListItemButton>
							</ListItem>
						))}
					</List>
				</Drawer>
			</AppBar>
			<Box sx={{
				padding: '1em 1em 0 1em'
			}}>
				<Switch>
					{menuItems.map(({ id, path, component }) => (
						<Route exact key={id} path={path} component={component} />
					))}
				</Switch>
			</Box>
		</Box>
	)

	function onClickLogout() {
		firebase.auth().signOut()
	}
}

export const getMenuItems = () => {
	const items: {
		id: string,
		title: string,
		path: string,
		component: () => any
	}[] = [
		{
			id: 'dashboard',
			title: 'Dashboard',
			path: '/',
			component: Dashboard
		},
		{
			id: 'projects',
			title: 'Projects',
			path: '/projects',
			component: Projects
		},
	]
	return items
}

export default React.memo(Home)