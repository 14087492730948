"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const firestar_1 = require("firestar");
var Analytics;
(function (Analytics) {
    function updateProjects(document) {
        return firestar_1.Actions.writeDocument('analytics/projects', document);
    }
    Analytics.updateProjects = updateProjects;
    function getProjects() {
        return firestar_1.Actions.readDocument('analytics/projects');
    }
    Analytics.getProjects = getProjects;
    function updateStores(document) {
        return firestar_1.Actions.writeDocument('analytics/stores', document);
    }
    Analytics.updateStores = updateStores;
    function getStores() {
        return firestar_1.Actions.readDocument('analytics/stores');
    }
    Analytics.getStores = getStores;
    function updateUsers(document) {
        return firestar_1.Actions.writeDocument('analytics/users', document);
    }
    Analytics.updateUsers = updateUsers;
    function getUsers() {
        return firestar_1.Actions.readDocument('analytics/users');
    }
    Analytics.getUsers = getUsers;
})(Analytics || (Analytics = {}));
exports.default = Analytics;
