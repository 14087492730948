import React, { useState } from 'react'
import firebase from 'firebase/compat/app'

export default function Login() {

	const [showLoader, setShowLoader] = useState(false)

	if (showLoader == true) {
		return (<div>Logging in..</div>)
	}

	return (
		<div>
			<button onClick={onPressLogin}>Login</button>
		</div>
	)

	async function onPressLogin() {
		setShowLoader(true)
		try {
			const googleSignIn = new firebase.auth.GoogleAuthProvider()
			await firebase.auth().signInWithPopup(googleSignIn)
		} catch (error) {
			console.log('Error Sign In : ',error)
		}
		setShowLoader(false)
	}
}
