import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import 'firebase/compat/database'
import 'firebase/compat/storage'
import firestar from 'firestar'
import AppConfig from './app.config.json';

const env = process.env.REACT_APP_ENV

if (firebase.apps.length == 0) {
	const app = firebase.initializeApp(AppConfig.firebase)
	firestar.init(
		'motherboard',
		'browser',
		() => firebase.firestore,
		() => firebase.auth,
		() => firebase.storage,
		() => firebase.database,
		env == 'emulator' ? {
			app: app as any,
			host: 'http://localhost',
			port: {
				auth: 9099,
				database: 9000,
				firestore: 8080,
				storage: 9199
			}
		} : undefined
	)
}

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);
root.render(
	<React.StrictMode>
		{env == 'emulator' && <div style={{
			background: '#32a891',
			width: '100%',
			textAlign: 'center',
			color: 'white'
		}}>{env}</div>}
		<div style={{
			display: 'flex',
			flex: 1,
			justifyContent: 'center'
		}}>
			<App />
		</div>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
