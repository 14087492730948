"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RoleOptions = void 0;
const firestar_1 = require("firestar");
exports.RoleOptions = {
    'store': 'Store',
    'admin': 'Admin'
};
var Stores;
(function (Stores) {
    function getAll(descriptor) {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await firestar_1.Actions.readCollection('stores', descriptor);
                resolve(response);
            }
            catch (error) {
                reject(error);
            }
        });
    }
    Stores.getAll = getAll;
    function get(id) {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await firestar_1.Actions.readDocument('stores/' + id);
                resolve(response);
            }
            catch (error) {
                reject(error);
            }
        });
    }
    Stores.get = get;
    function update(storeId, document) {
        return firestar_1.Actions.updateDocument(`stores/${storeId}`, document);
    }
    Stores.update = update;
    function deleteStore(storeId) {
        return firestar_1.Actions.deleteDocument(`stores/${storeId}`);
    }
    Stores.deleteStore = deleteStore;
    function create(document) {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await firestar_1.Actions.addToCollection('stores', document);
                resolve(response);
            }
            catch (error) {
                reject(error);
            }
        });
    }
    Stores.create = create;
    function addUser(storeId, userId, document) {
        return firestar_1.Actions.writeDocument(`stores/${storeId}/users/${userId}`, document);
    }
    Stores.addUser = addUser;
    function deleteUser(storeId, userId) {
        return firestar_1.Actions.deleteDocument(`stores/${storeId}/users/${userId}`);
    }
    Stores.deleteUser = deleteUser;
    function getUsers(storeId, descriptor) {
        return firestar_1.Actions.readCollection(`stores/${storeId}/users`, descriptor);
    }
    Stores.getUsers = getUsers;
})(Stores || (Stores = {}));
exports.default = Stores;
